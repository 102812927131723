export const HOME = '/'
export const SHOP = '/shop/:product_category'
export const SUB_CATEGORY_SHOP = '/filter/:sub_category'
export const PRODUCT_DETAILS = '/product/:slug'
export const MARKET = '/market_store'
export const CART = '/cart'
export const WISHLIST = '/wishlist'
export const CHECKOUT = '/checkout'
export const REGISTER = '/register'
export const ABOUT = '/about'
export const CONTACT = '/contact'
export const HELP = '/help'
export const LOGIN = '/login'
export const SIGNUP = '/signup'
export const FORGOT_PASSWORD = '/forgot_password'
export const ADMIN_DASHBOARD = '/admin/Dashboard'
export const ALL_PRODUCT = '/admin/dashboard/AllProducts'
export const ADD_NEW_PRODUCT = '/admin/dashboard/AddNewProduct'
export const PRODUCT_RATE = '/admin/dashboard/ProductRate'
export const PRODUCT_RATE_DETAILS = '/admin/dashboard/Product/Rate/Details/:id'
export const ADMIN_PRODUCT_WISHLIST = '/admin/dashboard/product-wishlist'
export const VERIFY_EMAIL = '/verify-email'
export const EDIT_ADMIN_PRODUCT = '/admin/dashboard/edit-product/:slug'

export const ADMIN_ALL_USERS = '/admin/dashboard/all-users'
export const SELLERS = '/admin/dashboard/Sellers'
export const ADD_NEW_SELLER = '/admin/dashboard/Sellers/AddNewSeller'
export const SELLER_PAYOUT = '/admin/dashboard/Sellers/SellerPayout'

export const FLASH_DEAL = '/admin/dashboard/FlashDeal'
export const PROMO_DEAL = '/admin/dashboard/PromoDeal'
export const CREATE_FLASH_DEAL = '/admin/Dashboard/CreateFlashDeal'

export const REPORT_PRODUCT_SALE = '/admin/dashboard/Report/ProductSale'
export const REPORT_PRODUCT_STOCK = '/admin/dashboard/Report/ProductStock'
export const REPORT_SELLER_PRODUCT_SALE =
    '/admin/dashboard/Report/SellerProductSale'
export const REPORT_SHIPMENT = '/admin/dashboard/Report/Shipment'

export const CATEGORY = '/admin/dashboard/Category'
export const ADD_NEW_CATEGORY = '/admin/dashboard/AddNewCategory'
export const SHOP_ADMIN = '/admin/dashboard/BrandShop'
export const Uploading_Files = '/admin/Dashboard/UploadingFiles'
export const CUSTOMER_DASHBOARD = '/profile/dashboard'
export const MANAGE_PROFILE = '/profile/manage-profile'
export const PAYMENT_METHOD = '/profile/payment-method'
export const ADD_PAYMENT_METHOD = '/profile/add-payment-method'
export const PURCHASE_HISTORY = '/profile/purshase-history'
export const PROFILE_UPDATE_INFO = '/profile/update-info'
export const PROFILE_UPDATE_EMAIL = '/profile/update-email'
export const PROFILE_UPDATE_PASSWORD = '/profile/update-password'
export const PROFILE_UPDATE_PHONE = '/profile/update-phone'
export const PROFILE_UPDATE_AVATAR = '/profile/update-avatar'
export const PROFILE_VERIFY_EMAIL = '/profile/verify-email'
export const PROFILE_VERIFY_PHONE = '/profile/verify-phone'
export const BECOME_SELLER = '/profile/become-a-seller'
export const REVIEWS = '/reviews/:slug'
export const ADD_REVIEW = '/profile/add-review/:slug'
export const SELLER_PRODUCTS_REVIEWS = '/seller/products/reviews'
// export const SEARCH = '/search/:searchKey'

//Checkout
export const CHECKOUT_CASH = '/checkout/cash'
export const CHECKOUT_PAYPAL = '/checkout/paypal'

// Seller routes
export const SELLER_DASHBOARD = '/seller/Dashboard'
export const SHOP_VERIFICATION = '/seller/shop-verification'
export const SELLER_PURCHASE_HISTORY = '/seller/purchase-history'
export const SELLER_CHAT = '/seller/chat'
export const SELLER_PROFILE = '/seller/profile'
export const SELLER_CUSTOMERS = '/seller/customers/customers'
export const SELLER_ORDERS = '/seller/customers/orders'
export const SELLER_REPORT_PRODUCT = '/seller/reports/product-sale'
export const SELLER_REPORT_SALE = '/seller/reports/seller-product-sale'
export const SELLER_REPORT_STOCK = '/seller/reports/product-stock'
export const SELLER_REPORT_WISHLIST = '/seller/reports/product-wishlist'
export const SELLER_REPORT_SHIPMENT = '/seller/reports/shipment'
export const SELLER_FLASH_DEAL = '/seller/flash-deal'
export const SELLER_PROMO_DEAL = '/seller/promo-deal'
export const SELLER_CREATE_FLASH_DEAL = '/seller/create-flash-deal'
export const SELLER_PRODUCTS_ADD = '/seller/products/add-product'
export const SELLER_PRODUCTS_ALL = '/seller/products/all-product'
export const SELLER_PRODUCTS_CATEGORIES = '/seller/products/all-categories'
export const SELLER_PRODUCTS_ADD_CATEGORY = '/seller/products/add-category'
export const SELLER_PRODUCTS_BRANDS = '/seller/products/brands'
export const SELLER_PRODUCTS_EDIT = '/seller/products/edit-product/:slug'
export const SELLER_ORDER_EDIT = '/seller/customers/edit-order/:id'
export const SELLER_PRODUCTS_SUB_CATEGORY = '/seller/products/sub-categories'
export const SELLER_PRODUCTS_ADD_SUB_CATEGORY =
    '/seller/products/add-sub-categories'
export const ADMIN_PRODUCTS_ADD_COUPON = '/admin/dashboard/add-coupon'
export const ADMIN_ALL_COUPONS = '/admin/dashboard/all-coupon'
export const ADDS_MANAGER = '/admin/dashboard/AddonManager'
export const CUSTOMER = '/admin/dashboard/Customers'
export const ORDERS = '/admin/dashboard/Orders'
export const SALES = '/admin/dashboard/Sales'

export const NOT_FOUND = '/:notFound'
